import { Component, inject, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaypalData } from 'src/app/core/models/user';
import { CommonService } from 'src/app/core/services/api/common.service';
import { NotifierService } from 'src/app/core/services/notifier.services';
import { TriggerPayout } from '../../../../core/models/payouts';
import { UserService } from 'src/app/core/services/api/user.service';
import { map, switchMap } from 'rxjs';
import { PayoutMethodsService } from 'src/app/core/services/api/payout-methods.service';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';

@Component({
  selector: 'app-payouts-payment',
  templateUrl: './payouts-payment.component.html',
  styleUrls: ['./payouts-payment.component.scss']
})
export class PayoutsPaymentComponent implements OnInit {

  userService = inject(UserService);
  payoutMethodsService = inject(PayoutMethodsService);
  
  form: UntypedFormGroup;
  loader: boolean = false;
  recipientTypes: { value: string, text: string }[] = [];
  usePrefix: boolean = false;
  receiverPrefix: string = "";
  inputValidator: string = "";

  payoutMethods$ = this.userService.user.pipe(
    switchMap(user => this.payoutMethodsService.getAll()),
    map(methods => {
      return {
        default: methods.find(method => method.default),
        nonDefault: methods.filter(method => !method.default)
      }
    })
  );

  constructor(

    @Inject(MAT_DIALOG_DATA) public defaults,
    private dialogRef: MatDialogRef<PayoutsPaymentComponent>,
    private fb: UntypedFormBuilder,
    public commonService: CommonService,
    private notifier: NotifierService,
    private userPayoutService: UserPayoutsService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      amount: [this.defaults.amount, Validators.required],
      payout_type: ['', Validators.required],
      recipient_type: ['', Validators.required],
      receiver: ['', Validators.required],
      password: ['', Validators.required],
      saveMethod: ['']
    });
  }

  lastPayoutDetails() {
    this.form.patchValue({
      payout_type: this.defaults.data.payoutType,
      recipient_type: this.defaults.data.recipientType,
      receiver: this.defaults.data.receiver,
    })
    this.filterRecipientTypes(this.defaults.data.payoutType);
  }

  setPayoutType(mode: string, receiver: string) {
    this.form.patchValue({ payout_type: mode, receiver: receiver });
    this.filterRecipientTypes(mode);
  }

  filterRecipientTypes(mode: string): void {
    if (mode === 'Paypal') {
      this.form.patchValue({
        payout_type: "Paypal",
        recipient_type: "EMAIL"
      })
      this.recipientTypes = [
        { value: "EMAIL", text: "PayPal Email" }
      ]
    } else if (mode === 'Venmo') {
      this.form.patchValue({
        payout_type: "Venmo",
        recipient_type: "USER_HANDLE"
      })
      this.recipientTypeChange("USER_HANDLE");
      this.recipientTypes = [
        { value: "EMAIL", text: "Email" },
        { value: "USER_HANDLE", text: "Venmo Username" },
        { value: "PHONE", text: "Phone Number" }
      ]
    } else if (mode === 'Stripe') {
      this.form.patchValue({
        payout_type: "Stripe",
        recipient_type: "ACCOUNT_ID"
      });
    } else {
      this.recipientTypes = [];
    }
  }

  recipientTypeChange(type: string) {
    let receiver = '';
    switch (type) {
      case 'EMAIL':
        this.inputValidator = null;
        this.usePrefix = false;
        this.receiverPrefix = "";
        receiver = this.defaults.user.email;
        this.form.controls['receiver'].setValidators([Validators.required, Validators.email]);
        this.form.patchValue({ receiver: receiver });
        break;
      case 'PHONE':
        this.inputValidator = "phone";
        this.usePrefix = true;
        this.receiverPrefix = "+1";
        receiver = this.defaults.user.phone;
        this.form.controls['receiver'].setValidators([Validators.required]);
        this.form.patchValue({ receiver: receiver });
        break;
      case 'USER_HANDLE':
        this.inputValidator = null;
        this.form.controls['receiver'].setValidators([Validators.required]);
        this.usePrefix = true;
        this.receiverPrefix = "@";
        this.form.patchValue({ receiver: receiver });
        break;
      case 'STRIPE':
        this.inputValidator = null;
        this.form.controls['receiver'].setValidators([]);
        this.usePrefix = false;
        this.receiverPrefix = "";
        this.form.patchValue({ 
          receiver: receiver ,
          recipient_type: "ACCOUNT_ID" 
        });
        break;
      default:
        this.inputValidator = null;
        this.form.controls['receiver'].setValidators([Validators.required]);
        this.usePrefix = false;
        this.receiverPrefix = "";
        this.form.patchValue({ receiver: receiver });
        break;
    }
    this.form.controls['receiver'].updateValueAndValidity();

  }

  save() {
    if (this.form.value.saveMethod === '') {
      this.form.value.saveMethod = false
    }
    const formData: TriggerPayout = this.form.value;
    this.loader = true;
    if (formData.recipient_type === "USER_HANDLE" && formData.receiver.charAt(0) === "@") {
      formData.receiver = formData.receiver.substring(1);
    }
    this.userPayoutService.triggerPayout(formData).subscribe(res => {
      this.loader = false;
      this.notifier.alert({ message: 'Updated Successfully! Please allow 5-10 minutes for your payout to be processed.', type: 'success', duration: 10} )
      this.dialogRef.close(res);
    }, err => { this.loader = false; });
  }

  paypalConfirmed(paypalData: PaypalData): void {
    if (this.defaults.data) {
      this.defaults.data.receiver = paypalData.email;
    }
    this.form.patchValue({
      payout_type: "Paypal",
      receiver: paypalData.email,
      recipient_type: "EMAIL"
    })
  }

  getPaymentInfoValue(): boolean {
    if (
      this.form.value.payout_type &&
      this.form.value.receiver &&
      this.form.value.recipient_type
    ) {
      return true;
    }
    return false;
  }


}
