import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { BadgeType } from 'src/app/core/components/badge/badge.component';
import { CategorySalesReport } from 'src/app/core/models/sales';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';
import { UserService } from 'src/app/core/services/api/user.service';

@Component({
  selector: 'app-payout-detail',
  templateUrl: './payout-detail.component.html',
  styleUrls: ['./payout-detail.component.scss']
})
export class PayoutDetailComponent {
  route = inject(ActivatedRoute);
  payoutService = inject(UserPayoutsService);
  userService = inject(UserService);

  payout$ = this.route.params.pipe(
    switchMap(({id}) => this.payoutService.get(id))
  )

  teamSales$ = this.route.params.pipe(
    switchMap(({id}) => this.payoutService.teamSales(id))
  )

  public tableColumnsRoster: TableColumn<CategorySalesReport>[] = [
    {
      label: 'Roster Name',
      property: 'sportName',
      type: 'text'
    },
    {
      label: 'Items Sold',
      property: 'count',
      type: 'text',
      cssClasses: ['text-secondary']
    },
    {
      label: 'Total Sales',
      property: 'sales',
      type: 'currency',
      cssClasses: ['font-medium']
    },
    {
      label: 'Earned',
      property: 'license',
      type: 'currency',
      cssClasses: ['font-medium']
    },
  ];


  constructor() {}

  getStatusColor(status: string): BadgeType {
      switch(status) {
        case 'Success':
          return 'green';
        case 'Pending':
          return 'blue';
        case 'next payout':
          return 'blue';
        case 'Denied':
          return 'red';
        case 'FAILED':
          return 'red';
        default:
          return 'gray';
      }
    }
}
