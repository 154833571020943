import { AfterViewInit, Component, inject, Input, ViewChild } from '@angular/core';
import { Transaction, TransactionStatus, UserPayoutsService } from '../../services/api/user-payouts.service';
import { PaginatedRequest } from '../../models/utils';
import { CommonService } from '../../services/api/common.service';
import { debounceTime, distinctUntilChanged, Observable, ReplaySubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Payouts } from '../../models/payouts';
import { ExportService } from '../../services/api/export.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/api/user.service';
import { TransactionDialogComponent } from './transaction-dialog/transaction-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class TransactionsComponent implements AfterViewInit {
  @Input({required: false}) payout: Payouts;

  exportColumns = [
    'blankDescription',
    'saleDate',
    'salePrice',
    'licenseAmount',
    'payout',
    'transactionDate',
    'transactionStatus'
  ]

  payoutService = inject(UserPayoutsService);
  exportService = inject(ExportService);
  commonService = inject(CommonService);
  userService = inject(UserService);
  route = inject(ActivatedRoute);

  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  subject$: ReplaySubject<Transaction[]> = new ReplaySubject<Transaction[]>();
  data$: Observable<Transaction[]>;
  dataSource = new MatTableDataSource<Transaction>();
  searchCtrl = new UntypedFormControl();
  loader = false;
  dialog = inject(MatDialog);

  paginationStatus:  PaginatedRequest<Transaction> = {
    page: 1,
    page_size: 25,
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  columns: TableColumn<Transaction>[] = [
    { label: 'Image', property: 'image', type: 'image', visible: true },
    { label: 'Date', property: 'transactionDate', type: 'date', visible: true },
    { label: 'Product', property: 'blankDescription', type: 'text', visible: true },
    { label: 'License', property: 'licenseDetails', type: 'text', visible: true },
    { label: 'Sale Price', property: 'salePrice', type: 'currency', visible: true },
    { label: 'Earned', property: 'licenseAmount', type: 'currency', visible: true },
    { label: 'Status', property: 'transactionStatus', type: 'badge', visible: true },
  ];

  constructor() {
    
  }


  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paginationStatus = { ...this.paginationStatus, ...params };
    });
    this.updatePage();
    this.searchCtrl.valueChanges.pipe(
      debounceTime(200), distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(value => {
      if (value == '' || value.length > 2) {
        this.paginationStatus.search = value;
        this.paginationStatus.page = 1;
        this.updatePage();
      };
    });
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getStatusColor(status: TransactionStatus) {
    switch(status) {
      case 'Success':
        return 'green';
      case 'Pending':
        return 'blue';
      case 'next payout':
        return 'blue';
      case 'Denied':
        return 'red';
      case 'FAILED':
        return 'red';
      default:
        return 'gray';
    }
  }

  pageChanged(event: PageEvent) {
    this.paginationStatus.page_size = event.pageSize;
    this.paginationStatus.page = event.pageIndex + 1;
    this.updatePage();
  }

  updatePage(): void {
    this.loader = true;
    const access = this.userService.getPortalAccess();
    if (access) {
      this.paginationStatus.model = access.group.toLowerCase() as 'school' | 'schoolsport' | 'player' | 'organization' | 'licenseentity';
      this.paginationStatus.partnerId = access.id;
    }
    this.payoutService.transactions(this.payout?.id, this.paginationStatus).subscribe((transactions) => {
      this.dataSource.data = transactions.results;
      this.loader = false;
      setTimeout(() => {
        this.paginator.pageIndex = this.paginationStatus.page - 1;
        this.paginator.length = transactions.count;
      })
    });
  }

  exportCsv() {
    let page = this.paginationStatus;
    delete page.page_size;
    delete page.page;
    this.payoutService.transactions(this.payout?.id, page).subscribe(pageData => {
      const newHeaders = [];
      const headers = [];
      if (this.columns) {
        this.columns.filter(c => this.exportColumns.includes(c.property)).forEach(c => {
          newHeaders.push(c.label);
          headers.push(c.property);
        })
      }
      this.exportService.downloadCsvFile(pageData, headers, `transactions.csv`, newHeaders);
    });
  }
  

  viewTransaction(transaction: Transaction) {
    this.dialog.open(TransactionDialogComponent, {
      data: transaction
    })
  }



}
