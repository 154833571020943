import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mergeMap, of, Observable, Subject } from 'rxjs';
import { PortalAccess, User } from '../../models/user';
import { HttpHelperService } from '../http-helper.service';
import { SchoolService } from './school.service';
import { School, SchoolSport } from '../../models/school';
import { Router } from '@angular/router';
import { NavigationService } from '../../../../@vex/services/navigation.service';
import { ALL_NAV_ROUTES } from '../../models/navigation';
import { NavigationDropdown, NavigationItem, NavigationLink } from 'src/@vex/interfaces/navigation-item.interface';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { loadUser, UserState } from 'src/app/ngrx-store/actions/user.actions';
import { getSelectUser } from 'src/app/ngrx-store/reducers/user.reducers';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public currentUser: User = null;
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private httpHelper: HttpHelperService,
    private schoolService: SchoolService,
    private router: Router,
    private navigationService: NavigationService,
    private store: Store<UserState>,
    private usersService: UsersService
  ) {
    store.select(getSelectUser).subscribe(result => {
      this.currentUser = result;
    })
  }

  loadUser(): Observable<any> {
    return this.user.pipe(
      take(1),
      mergeMap(user => {
        if (!user) {
          this.httpHelper.request('get-user/');
        } else {
          return of(user);
        }
      }),
      mergeMap((user: User) => {
        this.usersService.userType = user?.group;
        // this.currentUser = user;
        if (user.group == 'ADMIN' || user.group == 'ORG' ||  user.group == 'ARTWORK') {
          let access = this.getPortalAccess();
          if (access && access.group) {
            user.accessBy = user.group;
            this.currentUser.group = access.group;
            if (access.group == 'SCHOOL') this.currentUser.schoolId = access.id;
          }
        }
        if (this.currentUser.schoolId && user.group != 'ORG') {
          return this.schoolService.get(this.currentUser.schoolId)
        } else {
          return of({})
        }
      }),
      tap(school => this.setUserContextData(school)),
      mergeMap(school => {
        return of(this.currentUser.group)
      })
    )
  }

  private setUserContextData(school: School): void {
    this.currentUser = { ...this.currentUser, school: school };
    if (this.isAdmin()) {
      this.currentUser.profilePicture = 'assets/img/icon.png'
    } else if (this.isSchool()) {
      this.currentUser.profilePicture = this.currentUser.school.mainLogo;
    } else if (this.isPlayer()) {
      if (this.currentUser.players && this.currentUser.players?.length > 0 && this.currentUser.players[0].headshot) {
        this.currentUser.profilePicture = this.currentUser.players[0].headshot;
      } else {
        this.currentUser.profilePicture = this.currentUser.school.mainLogo;
      }
    } else if (this.isOrg()) {
      this.currentUser.logo = 'assets/img/icon.png'
    }

    this.user.next(this.currentUser);
    this.setNav(this.currentUser.group);
  }

  updateUserTeam(schoolSport: SchoolSport): void {
    const idx = this.currentUser.school.teams.findIndex(t => t.id === schoolSport.id);
    if (idx > -1) {
      this.currentUser.school.teams[idx] = schoolSport;
    } else {
      this.currentUser.school.teams.push(schoolSport);
    }
    this.user.next(this.currentUser);
  }

  deleteUserTeam(ssId: number): void {
    this.currentUser.school.teams = this.currentUser.school.teams.filter(t => t.id !== ssId);
    this.user.next(this.currentUser);
  }

  removeUser(): void {
    this.currentUser = null;
    this.user.next(null);
  }

  setSchool(school: School): void {
    this.currentUser.school = school;
    this.user.next(this.currentUser);
  }

  setSchoolLogo(field: string, value: string) {
    if (this.currentUser.school && this.currentUser.school[field]) {
      this.currentUser.school[field] = value;
      this.user.next(this.currentUser);
    }
  }

  setRosterNav(group: string) {
    if (!this.currentUser || !this.currentUser.school) return [];
    if (!group) return [];
    let rosterNav: NavigationLink[] = [];
    if (group == 'SCHOOL') {
      this.currentUser.school.teams.forEach(element => rosterNav.push({
        type: 'link',
        route: "school/rosters/" + element.id,
        icon: element.sport.icon,
        label: `${element.gender} ${element.sport.name}`,
        groupNames: ["SCHOOL"]
      }));
    }

    const rosterParentNav: NavigationDropdown[] = [];
    if (rosterNav.length > 0) rosterParentNav.push({ type: 'dropdown', label: "Roster", icon: 'device_hub', groupNames: [group], children: rosterNav });

    return rosterParentNav;
  }

  setNav(group: string): void {
    let navItems: Array<NavigationLink | NavigationDropdown> = [...ALL_NAV_ROUTES.filter(r => r.groupNames?.some(gn => group.includes(gn)))];
    if (group == 'PLAYER') {
      if (!this.currentUser.status) {
        navItems = navItems.filter(row => row.value != 'SALES');
      }
      if (!this.currentUser.school.playerDesignsAllowed) {
        navItems = navItems.filter(row => row.value != 'DESIGNS');
      }
      if (this.currentUser.school.noPayouts || this.currentUser.payoutsDisabled) {
        navItems = navItems.filter((n: NavigationLink) => !n?.tags?.includes('noPayouts'));
      }
      if (!this.currentUser.school.nilPlusEnabled) {
        navItems = navItems.filter((n: NavigationLink) => !n?.tags?.includes('nilPlus'));
      }
      if (!this.currentUser.school.playerSignups || !this.currentUser.school.brandAmbassadorsEnabled) {
        navItems = navItems.filter((n: NavigationLink) => !n?.tags?.includes('playerSignUps'));
      }
    }
    if (group === 'SCHOOL' && !this.currentUser.school.buildAt) {

      navItems = navItems.filter((n: NavigationLink) => !n.tags?.includes('afterBuild'));
      navItems.forEach(n => {
        if (n.type === 'dropdown') {
          n.children = n.children.filter((n: NavigationLink) => !n.tags?.includes('afterBuild'));
        }
      })
    }

    if (group === 'ADMIN') {
      if (!this.currentUser.hasCommissions) {
        navItems = navItems.filter((n: NavigationLink) => !n?.tags?.includes('hasCommissions'));
      }
    }

    const nav: NavigationItem[] = [
      {
        type: 'subheading',
        label: 'Configuration',
        children: navItems
      }
    ];
    this.navigationService.updateNavItems(nav);
  }

  isSchool(): boolean {
    if (this.currentUser.group === 'SCHOOL') {
      return true;
    }
    return false;
  }

  isPlayer(): boolean {
    if (this.currentUser.group === 'PLAYER') {
      return true;
    }
    return false;
  }
  isOrg(): boolean {
    if (this.currentUser.group === 'ORG') {
      return true;
    }
    return false;
  }

  isAdmin(): boolean {
    if (this.currentUser.group === 'ADMIN' || this.currentUser.accessBy === 'ADMIN' || this.currentUser.group == "ARTWORK") {
      return true;
    }
    return false;
  }

  isManufacturer(): boolean {
    if (this.currentUser.group === 'MANUFACTURER') {
      return true;
    }
    return false;
  }

  isArtwork(): boolean {
    if (this.currentUser.group === "ARTWORK") {
      return true;
    }
    return false;
  }

  isSuperuser(): boolean {
    return this.currentUser.isSuperuser;
  }

  isReadOnly(): boolean {
    if (this.currentUser.group === 'READ_ONLY' || this.currentUser.accessBy === 'READ_ONLY') {
      return true;
    }
    return false;
  }

  payoutsDisabled(): boolean {
    if (this.currentUser.group === 'ORG' ||
      (this.currentUser.group === 'PLAYER' && (this.currentUser.school.noPayouts || this.currentUser.payoutsDisabled))
    ) {
      return true;
    }
    return false;
  }

  navigateHome(group?: string | null): void {
    if (this.currentUser && this.currentUser.group) {
      let url = (this.currentUser.accessBy) ? this.currentUser.accessBy.toLowerCase() : this.currentUser.group.toLowerCase();
      if (group) url = group.toLowerCase();
      if (url === 'artwork') {
        this.router.navigate(['/admin' + '/artwork-sales']);
        return;
      };
      if (url === 'read_only') {
        this.router.navigate(['/admin' + '/commissions']);
        return;
      }
      this.router.navigate(['/' + url + '/home']);
    }
    else {
      this.router.navigate(['/']);
    }
  }

  getAuthTokenFromLocalStorage() {
    return localStorage.getItem('token');
  }

  getUser(): Observable<boolean> {
    const tempToken = this.getAuthTokenFromLocalStorage()
    return this.httpHelper.request('get-user/').pipe(
      map((user: User) => {
        if (!user) return false;
        if (user.group == 'ADMIN') {
          const accessExist = this.getPortalAccess();
          if (accessExist && accessExist.group) {
            user.accessBy = 'ADMIN';
            user.group = accessExist.group;
            if (user.group === 'SCHOOL') user.schoolId = accessExist.id;
          }
        } else if (user.group == 'ORG') {
          const accessExist = this.getPortalAccess();
          if (accessExist && accessExist.group) {
            user.accessBy = 'ORG';
            user.group = accessExist.group;
            if (user.group === 'SCHOOL') user.schoolId = accessExist.id;
          }
        } else if (user.group == 'READ_ONLY') {
          const accessExist = this.getPortalAccess();
          if (accessExist && accessExist.group) {
            user.accessBy = 'ADMIN';
            user.group = accessExist.group;
            if (user.group === 'SCHOOL') user.schoolId = accessExist.id;
          }
        } else if (user.group == 'ARTWORK') {
          const accessExist = this.getPortalAccess();
          if (accessExist && accessExist.group) {
            user.accessBy = 'ADMIN';
            user.group = accessExist.group;
            if (user.group === 'SCHOOL') user.schoolId = accessExist.id;
          }
        }
        this.currentUser = user;
        this.user.next(this.currentUser);
        this.store.dispatch(loadUser({ payload: { user: user, token: tempToken } }));
        return true;
      }, error => false)
    );
  }
  setPortalAccess(data: PortalAccess) {
    localStorage.setItem('access', JSON.stringify(data));
  }
  getPortalAccess() {
    return JSON.parse(localStorage.getItem('access'));
  }
  portalAccessLogout() {
    localStorage.removeItem('access');
  }
}
