import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private endpoint: string = 'stripe';

  constructor(private httpHelper: HttpHelperService) { }

  createAccount(): Observable<{account: string}> {
    return this.httpHelper.request<{account: string}>(`${this.endpoint}/create-account`, { method: 'POST' });
  }

  getLink(accountId: string): Observable<{link: string}> {
    return this.httpHelper.request<{link: string}>(`${this.endpoint}/account-link`, { body: {account: accountId}, method: 'POST' });
  }

  accountSession(): Observable<{clientSecret: string}> {
    return this.httpHelper.request<{clientSecret: string}>(`${this.endpoint}/account-session`, { method: 'GET' });
  }

  getAccount(): Observable<{bankName: string, accountNumber: string}> {
    return this.httpHelper.request<{bankName: string, accountNumber: string}>(`${this.endpoint}/account-info`, { method: 'GET' });
  }

}
