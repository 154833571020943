// src/app/core/components/payout-methods/payout-method-card/payout-method-card.component.ts
import { Component, Input } from '@angular/core';
import { PayoutMethod } from '../../models/payouts';

@Component({
  selector: 'app-payout-method-card',
  template: `
    <ng-container [ngSwitch]="payoutMethod.payoutType">
      <app-stripe-payout-card 
        *ngSwitchCase="'Stripe'"
        [payoutMethod]="payoutMethod"
        (onVerificationComplete)="handleVerificationComplete()">
      </app-stripe-payout-card>
      
      <app-paypal-payout-card 
        *ngSwitchCase="'Paypal'"
        [payoutMethod]="payoutMethod"
        (onVerificationComplete)="handleVerificationComplete()">
      </app-paypal-payout-card>
      
      <app-venmo-payout-card 
        *ngSwitchCase="'Venmo'"
        [payoutMethod]="payoutMethod"
        (onVerificationComplete)="handleVerificationComplete()">
      </app-venmo-payout-card>
    </ng-container>
  `
})
export class PayoutMethodCardComponent {
  @Input({required: true}) payoutMethod: PayoutMethod;

  handleVerificationComplete(): void {
    // Handle verification completion (e.g., refresh data)
  }
}