<vex-page-layout>
  <vex-page-layout-content class="container" *ngIf="payout$ | async as payout">
    <div>
      <div class="flex flex-row gap-3 items-center my-4 justify-between">
        <div class="flex flex-row items-center">
          <mat-icon [routerLink]="['/user/payouts']" class="hover:text-gray-500 cursor-pointer">arrow_back</mat-icon>
          <span class="ml-3 text-lg font-semibold">
            Payouts
          </span>
        </div>
      </div>
    </div>
    <div class="card p-6 relative h-full flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-one mb-4">
      <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Payout Info
      </h3>
      <div class="flex items-center flex-col md:flex-row justify-between mt-auto">
        <div class="">
          <h2 class="font-bold color-dark text-2xl">{{ payout.amount | currency }}</h2>
          <p>Submitted: {{ payout.submittedAt | date: 'MMM d, yyyy' }}</p>
          <p>Paid: {{ payout.acceptedAt | date: 'MMM d, yyyy' }}</p>
        </div>
        <app-badge [text]="payout.itemStatus" [type]="getStatusColor(payout.itemStatus)"></app-badge>
      </div>
    </div>
    <mat-tab-group>
      <mat-tab label="Transactions">
        <app-transactions [payout]="payout"></app-transactions>
      </mat-tab>
      <mat-tab *ngIf="userService.isSchool()" label="Team Sales">
        <div class="summary mt-2" *ngIf="teamSales$ | async as teamSales">
          <div class="recentSales pt-2">
            <vex-widget-table
              [blockLoader]="teamSales===null"
              [columns]="tableColumnsRoster"
              [data]="teamSales"
              [tableHeader]="'Team Sales'"
              [pageSizeOptions]="[5, 10, 25, 100]"
              class="sm:col-span-full mt-4">
            </vex-widget-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </vex-page-layout-content>
</vex-page-layout>