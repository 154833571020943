<mat-form-field class="w-full">
  <mat-label appRemoveAriaOwns>{{ label }}</mat-label>
  <input 
    matInput 
    class="form-control" 
    [formControl]="handleControl" 
    [value]="handleControl.value" 
    (input)="onInputChange($event)"
    [attr.disabled]="disabled ? '' : null">
  
  <!-- Add loading indicator -->
  <mat-spinner 
    *ngIf="isCheckingUniqueness" 
    matSuffix 
    diameter="16"
    class="ml-2">
  </mat-spinner>
  
  <!-- Add check icon when valid and not loading -->
  <mat-icon 
    *ngIf="!isCheckingUniqueness && handleControl.valid && handleControl.dirty" 
    matSuffix 
    class="text-green-500 ml-2">
    check_circle
  </mat-icon>
  
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error *ngIf="handleControl.errors?.['required']">Handle is required</mat-error>
  <mat-error *ngIf="handleControl.errors?.['minlength']">Handle must be at least {{ minLength }} characters</mat-error>
  <mat-error *ngIf="handleControl.errors?.['maxlength']">Handle cannot exceed {{ maxLength }} characters</mat-error>
  <mat-error *ngIf="handleControl.errors?.['handleExists']">This handle is already in use</mat-error>
</mat-form-field> 