<form [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <img *ngIf="this.defaults.mainLogo" [src]="this.defaults.mainLogo"
      (error)="commonService.imageErrorHandle($event)" class="avatar mr-5">

    <h2 *ngIf="form.get('fullName').value" class="text-base md:text-2xl m-0 flex-auto">{{ form.get('fullName').value }}</h2>
    <h2 *ngIf="!form.get('fullName').value" class="text-base md:text-2xl m-0 flex-auto">New School</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <mat-form-field class="flex-auto">
      <mat-label appRemoveAriaOwns>School Name</mat-label>
      <input cdkFocusInitial formControlName="fullName" matInput>

      <mat-icon class="mr-3" matPrefix>person</mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label appRemoveAriaOwns>Abbreviation</mat-label>
      <input formControlName="abbrName" matInput>

      <mat-icon class="mr-3" matPrefix>person</mat-icon>
    </mat-form-field>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label appRemoveAriaOwns>Letters</mat-label>
        <input formControlName="letters" matInput>

        <mat-icon class="mr-3" matPrefix>person</mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label appRemoveAriaOwns>Nickname</mat-label>
        <input formControlName="nickname" matInput>

        <mat-icon class="mr-3" matPrefix>person</mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field class="flex-auto">
      <mat-label appRemoveAriaOwns>Mascot</mat-label>
      <input formControlName="mascot" matInput>

      <mat-icon class="mr-3" matPrefix>person</mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label appRemoveAriaOwns>Chant</mat-label>
      <input formControlName="chant" matInput>
      <mat-icon class="mr-3" matPrefix>person</mat-icon>
    </mat-form-field>

    <app-handle-control 
      formControlName="handleString"
      [initialValue]="defaults.handleString"
      [hint]="'Handle/Sub-Domain of the store (i.e handle.athsolutions.shop)'"
      [label]="'Handle'"
      [required]="true"
      [minLength]="4"
      [maxLength]="25">
    </app-handle-control>

    <ng-container *ngIf="userService.isAdmin()">
      <mat-form-field *ngIf="userService.isSuperuser" class="flex-auto">
        <mat-label appRemoveAriaOwns>Commissions User</mat-label>
        <mat-select formControlName="commissionsUser">
          <mat-option *ngFor="let user of users$ | async" [value]="user.id">{{user.lastName}}, {{user.firstName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <mat-label appRemoveAriaOwns>Reporting Entities</mat-label>
        <mat-select formControlName="reportingEntities" [multiple]="true">
          <mat-option *ngFor="let entity of entities$ | async" [value]="entity.id">{{entity.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <mat-label appRemoveAriaOwns>Default License Type</mat-label>
        <mat-select formControlName="defaultLicenseType">
          <mat-option *ngFor="let code of defaultLicenseTypes$ | async" [value]="code?.code">{{code.description}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-auto" *ngIf="form.value.defaultLicenseType === 'Pandadoc'">
        <mat-label appRemoveAriaOwns>Player Required Documents</mat-label>
        <mat-select formControlName="playerRequiredTemplates" multiple="true">
          <mat-option *ngFor="let template of pandaTemplates" [value]="template.id">{{template.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-auto" >
        <mat-label appRemoveAriaOwns>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let code of statuses$ | async" [value]="code?.code">{{code.description}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button type="submit" [disabled]="!this.form.valid" (click)="save()">
      {{ (isCreateMode()) ? 'Create' : 'Update'}}
    </button>
  </mat-dialog-actions>
</form>
