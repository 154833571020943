// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBase: 'https://api-dev.athsolutions.net/api/',
  socialModule: 'https://storage.googleapis.com/gidget-static/dev.v.latest/gidget/gidget.esm.js',
  socialNoModule: 'https://storage.googleapis.com/gidget-static/dev.v.latest/gidget/gidget.js',
  socialDashboardBase: 'https://dashboard-stage.socialvenu.com/',
  socialAdminDashToken: '035a0468-1e66-4202-9d81-e3b9e621856b',
  paypalAAC: 'https://www.paypalobjects.com/payouts/js/payouts_aac.js',
  paypalClientId: "AdWSkRJ8_yoUmxCgmoAEfNusuwf-HbyxMvcP-M1T_x9i_daXSE_pi2PaMPpuHL4iRexw1Xv9gQNDHYKs",
  paypalMerchantId: '86HQEQCXFHPB4',
  coalesceBase: 'https://coalesceink.athsolutions.net/',
  stripePublishableKey: 'pk_test_51MhZBiDTcdGNzhognIi6eGWqVTTY0pKdOJHa7qlMyznQnXI6kUE9sh1bE6Du2jHrouK9gCTDi6YwGe4qfLCFeTcr00jHb0mAC3'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
