// src/app/core/components/payout-methods/paypal-payout-card/paypal-payout-card.component.ts
import { Component, Input } from '@angular/core';
import { PayoutMethod, PayoutMethodComponent } from '../../models/payouts';

@Component({
  selector: 'app-venmo-payout-card',
  template: `
    <div class="flex flex-row justify-between gap-4">
      <mat-icon>account_balance</mat-icon>
      <span class="font-semibold">{{payoutMethod.displayName}}</span>
      <span class="text-gray-500">PayPal</span>
      <div class="flex-1"></div>
    </div>
    <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-3" *ngIf="!payoutMethod.enabled">
      <div class="flex">
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-yellow-700">Please verify your PayPal account to receive payments.</p>
          <button class="mt-3 text-sm md:ml-6 md:mt-0" (click)="verifyAccount()">
            <span class="rounded-full border border-yellow-700 px-3.5 py-1 font-bold whitespace-nowrap text-yellow-700 hover:text-yellow-600 cursor-pointer">
              Verify Account
              <span aria-hidden="true"> &rarr;</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  `
})
export class VenmoPayoutCardComponent implements PayoutMethodComponent {
  @Input({required: true}) payoutMethod: PayoutMethod;
  @Input() onVerificationComplete?: () => void;

  verifyAccount(): void {
    // Implement PayPal verification logic
  }
}