<div class="flex flex-col h-full">
  <div class="flex-0 flex items-center" mat-dialog-title>
    <h2 class="text-base md:text-2xl m-0 flex-auto">Transaction Details</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider class="text-border"></mat-divider>
  <mat-dialog-content class="flex-1 py-4 overflow-y w-96">
    <img class="w-80 flex-none bg-gray-50 mb-4 rounded-lg" [src]="data.image">
    
    <div class="grid grid-cols-2 gap-4">
      <div class="font-medium">Price:</div>
      <div>{{data.salePrice | currency}}</div>

      <div class="font-medium">Status:</div>
      <div>
        <span [class]="'px-2 py-1 rounded-full text-sm ' + 
          (data.transactionStatus === 'Success' ? 'bg-green-100 text-green-800' : 
           data.transactionStatus === 'next payout' ? 'bg-yellow-100 text-yellow-800' : 
           'bg-red-100 text-red-800')">
          {{data.transactionStatus | titlecase}}
        </span>
      </div>

      <div class="font-medium">Details:</div>
      <div>{{data.licenseDetails}}</div>

      <div class="font-medium">Date:</div>
      <div>{{data.transactionDate | date:'medium'}}</div>
    </div>
  </mat-dialog-content>
</div>


