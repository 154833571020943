<vex-page-layout>
  <vex-page-layout-content class="container">
    <ng-container *ngIf="!userService.isOrgOrAdminSignedIn()">
      <mat-card *ngIf="payoutMethods$ | async as payoutMethods" class="mb-5">
        <mat-card-header class="mb-3" >
          <mat-card-title>Payout Account</mat-card-title>
        </mat-card-header>
        <mat-card-content class="space-y-3" *ngIf="payoutMethods.default else noDefault">
          <mat-divider class="text-black mb-3"></mat-divider>
          <app-payout-method-card [payoutMethod]="payoutMethods.default"></app-payout-method-card>
          <ng-container *ngIf="payoutMethods.nonDefault && payoutMethods.nonDefault.length > 0">
            <mat-divider class="text-black"></mat-divider>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <div class="pb-5">
      <div class="have-school">
        <div class="total-status-of-sale td-padding-first-0 td-row grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">
          <div class="relative h-full flex flex-col justify-center full hover:shadow-lg text-primary">
            <div class="grid grid-cols-1 px-0 sm:grid-cols-1 md:grid-cols-1">
              <div [buttonLoader]="totalLoader"
                class="card p-6 relative flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-one h-28">
                <ng-container *ngIf="!totalLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Total Payouts
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                    <h2 class="font27 font-bold color-dark"> {{this.totalData?.total || 0 | currency}}</h2>
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon> attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div [buttonLoader]="totalDueLoader"
                class="card p-6 relative h-28 flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-two mt-3">
                <ng-container *ngIf="!totalDueLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Next Payout
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between mt-auto">
                    <a [routerLink]="['/user/transactions']" [queryParams]="{status: 'next payout'}" class="hover:underline font27 font-bold color-dark">{{this.totalDueData?.totalDue || 0 | currency}}</a>
                    <ng-container *ngIf="!totalDueLoader && userService.isPlayer()">
                      <div [matTooltipPosition]="'above'" [matTooltipShowDelay]="300" [matTooltipHideDelay]="1000"
                        matTooltip="Your available Payout must be greater than or equal to $5.00"
                        [matTooltipDisabled]="!(this.totalDueData?.totalDue === null ||this.totalDueData?.totalDue<5)">
                        <button color="primary" mat-raised-button color="primary" (click)="showPayout(payoutMethods.default)" *ngIf="payoutMethods$ | async as payoutMethods"
                          [disabled]="this.totalDueData?.totalDue === null ||this.totalDueData?.totalDue<5">Get Payout</button>
                      </div>
                    </ng-container>
                    <div *ngIf="!userService.isPlayer()" class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon>attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-payout-table (sendGraphData)="getGraphData($event)" [refresh]="refreshPayoutList" [payoutsDisplayedColumns]="payoutsDisplayedColumns" class="mt-4 block"></app-payout-table>
  </vex-page-layout-content>
</vex-page-layout>

<ng-template #noDefault>
  <mat-card-content class="space-y-3">
    <div class="bg-red-600 text-white p-4 rounded-md mb-4 flex items-start" *ngIf="!userService.isPlayer()">
      <mat-icon class="mr-2">warning</mat-icon>
      <div>
        <p class="font-medium">Payouts cannot be processed without a linked bank account</p>
        <p class="text-sm mt-1">You need to link a bank account to receive your earnings</p>
      </div>
    </div>
    <mat-divider class="text-black"></mat-divider>
    <div class="flex flex-row justify-between gap-4 items-center p-2">
      <mat-icon>account_balance</mat-icon>
      <span class="font-semibold">Connect Bank Account</span>
      <div class="flex-1"></div>
      <button *ngIf="!userService.isAdmin()" mat-raised-button color="primary" (click)="createAccount()" [buttonLoader]="createAccountLoader">Link Bank Account</button>
    </div>
  </mat-card-content>
</ng-template>