import { Component, inject, Input, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { PayoutMethod, PayoutMethodComponent } from '../../models/payouts';
import { StripeService } from '../../services/api/stripe.service';
import { PayoutMethodsService } from '../../services/api/payout-methods.service';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { firstValueFrom, map } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UserService } from '../../services/api/user.service';

@Component({
  selector: 'app-stripe-payout-card',
  template: `
    <div class="flex flex-col md:flex-row justify-between gap-4" [blockLoader]="loadingAccountInfo">
      
      <div class="flex items-center gap-2">
        <mat-icon>account_balance</mat-icon>
        <span class="font-semibold">{{payoutMethod.displayName}}</span>
        <span class="text-gray-500" *ngIf="payoutMethod.accountInfo">{{payoutMethod.accountInfo.bankName}} {{payoutMethod.accountInfo.maskedAccountNumber}}</span>
      </div>
      <div class="flex-1"></div>
      <div class="border-l-4 border-yellow-400 bg-yellow-50 p-2" *ngIf="!payoutMethod.enabled && payoutMethod.verificationStatus === 'unverified' && !userService.isAdmin()">
        <div class="flex">
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-yellow-700">Please verify your Stripe details to receive payments.</p>
            <button class="mt-3 text-sm md:ml-6 md:mt-0" (click)="verifyAccount()">
              <span class="rounded-full border border-yellow-700 px-3.5 py-1 font-bold whitespace-nowrap text-yellow-700 hover:text-yellow-600 cursor-pointer">
                Verify Account
                <span aria-hidden="true"> &rarr;</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <button class="mt-3 text-sm md:ml-6 md:mt-0" (click)="openStripeModal()" *ngIf="payoutMethod.enabled && payoutMethod.verificationStatus === 'verified' && !userService.isAdmin()">
        <span class="rounded-full border border-green-700 px-3.5 py-1 font-bold whitespace-nowrap text-green-700 hover:text-green-600 cursor-pointer">
          Update Account
          <span aria-hidden="true"> &rarr;</span>
        </span>
      </button>
    </div>
    
    <!-- Stripe Modal Template -->
    <ng-template #stripeModal>
      <div class="p-4">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl font-semibold">Connected Stripe Account</h2>
          <button mat-icon-button (click)="closeModal()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="stripe-container">
          <div id="account-container" class="mb-4"></div>
          <div id="documents-container"></div>
        </div>
      </div>
    </ng-template>
  `
})
export class StripePayoutCardComponent implements PayoutMethodComponent, OnInit {
  @Input({required: true}) payoutMethod: PayoutMethod;
  @Input() onVerificationComplete?: () => void;
  @ViewChild('stripeModal') stripeModalTemplate: TemplateRef<any>;

  stripeService = inject(StripeService);
  payoutMethodsService = inject(PayoutMethodsService);
  userService = inject(UserService);
  dialog = inject(MatDialog);
  
  loadingLink = false;
  loadingAccountInfo = false;
  dialogRef: MatDialogRef<any>;

  bankName = '';
  accountNumber = '';

  verifyAccount(): void {
    this.loadingLink = true;
    this.stripeService.getLink(this.payoutMethod.receiver).subscribe(({link}) => {
      window.location.href = link;
      this.loadingLink = false;
    });
  }

  ngOnInit(): void {
    this.payoutMethodsService.stripeAccountInfo(this.payoutMethod.id).subscribe((payoutMethod) => {
      this.payoutMethod.accountInfo = payoutMethod.accountInfo;
    });
  }

  openStripeModal(): void {
    this.dialogRef = this.dialog.open(this.stripeModalTemplate, {
      width: '800px',
      maxWidth: '95vw',
      maxHeight: '90vh',
      panelClass: 'stripe-modal-container'
    });

    // Initialize Stripe components after the modal is opened
    this.dialogRef.afterOpened().subscribe(() => {
      setTimeout(() => this.loadDashboard(), 100);
    });
  }

  closeModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  fetchClientSecret = async () => {
    return firstValueFrom(this.payoutMethodsService.stripeAccountLink(this.payoutMethod.id).pipe(
      map(data => {
        return data.clientSecret
      })
    ))
  }

  loadDashboard(): void {
    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: environment.stripePublishableKey,
      fetchClientSecret: this.fetchClientSecret,
    });
    
    const accountComponent = stripeConnectInstance.create("account-management");
    const documentsComponent = stripeConnectInstance.create("documents");
    
    const container = document.getElementById("account-container");
    if (container) container.appendChild(accountComponent);
    
    const documentsContainer = document.getElementById("documents-container");
    if (documentsContainer) documentsContainer.appendChild(documentsComponent);
  }
}