import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MaterialModule } from '../modules/material/material.module';
import { RouterModule } from '@angular/router';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { SchoolSportPreviewComponent } from './components/school-sport-preview/school-sport-preview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlankComponent } from './components/blank/blank.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { DocumentsComponent } from './components/documents/documents.component';
import { DocumentViewComponent } from './components/documents/document-view/document-view.component';
import { ScrollbarModule } from 'src/@vex/components/scrollbar/scrollbar.module';
import { DocumentListEntryComponent } from './components/documents/document-list-entry/document-list-entry.component';
import { RelativeDateTimeModule } from 'src/@vex/pipes/relative-date-time/relative-date-time.module';
import { NotifierComponent } from './components/notifier/notifier.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PlayerDocsComponent } from './components/player-docs/player-docs.component';
import { PlayerDocsViewComponent } from './components/player-docs/player-docs-view/player-docs-view.component';
import { PlayerDocsListComponent } from './components/player-docs/player-docs-list/player-docs-list.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoadingDirective } from './directives/loading.directive';
import { DocumentSignersModalComponent } from './components/documents/document-signers-modal/document-signers-modal.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SchoolsComponent } from './components/schools/schools.component';
import { SchoolCreateUpdateComponent } from './components/schools/school-create-update/school-create-update.component';
import { SchoolDeleteBoxComponent } from './components/schools/school-delete-box/school-delete-box.component';
import { SchoolImageUploadComponent } from './components/schools/school-image-upload/school-image-upload.component';
import { BlockLoaderDirective } from './directives/block-loader.directive';
import { LoadingBlockComponent } from './components/loading-block/loading-block.component';
import { ButtonLoaderDirective } from './directives/button-loader.directive';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { PayoutTableComponent } from './components/payout-table/payout-table.component';
import { SchoolSportUpdateCreateComponent } from './components/schools/school-sport-update-create/school-sport-update-create.component';
import { SchoolTemplateDesignViewComponent } from './components/school-template-design-view/school-template-design-view.component';
import { SchoolTemplateDesignViewUpdateComponent } from './components/school-template-design-view-update/school-template-design-view-update.component';
import { DownloadFileComponent } from './components/download-file/download-file.component';
import { ConfigBannerComponent } from './components/config-banner/config-banner.component';
import { VerificationResponseDialogComponent } from './components/verification-response-dialog/verification-response-dialog.component';
import { StatsBannerComponent } from './components/stats-banner/stats-banner.component';
import { SchoolLogoItemComponent } from './components/school-logo-item/school-logo-item.component';
import { AllLogosModalComponent } from './components/all-logos-modal/all-logos-modal.component';
import { BlankSelectionModalComponent } from './components/blank-selection-modal/blank-selection-modal.component';
import { ProductModelMenuComponent } from './components/product-model-menu/product-model-menu.component';
import { ProductsViewComponent } from './components/products-view/products-view.component';
import { DesignSelectionModalComponent } from './components/design-selection-modal/design-selection-modal.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { PrintLocationModalComponent } from './components/print-location-modal/print-location-modal.component';
import { UnsavedChangesModalComponent } from './components/unsaved-changes-modal/unsaved-changes-modal.component';
import { CopyToClipboardModalComponent } from './components/copy-to-clipboard-modal/copy-to-clipboard-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LogoSelectorComponent } from './components/logo-selector/logo-selector.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { RemoveAriaOwnsDirective } from './directives/remove-aria-owns.directive';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { DesignLicensingComponent } from './components/design-licensing/design-licensing.component';
import { ImagePreviewModalComponent } from './components/image-preview-modal/image-preview-modal.component';
import { OrderAuditLogsModalComponent } from './components/order-audit-logs-modal/order-audit-logs-modal.component';
import { SchoolSportTileComponent } from './components/schools/school-sport-tile/school-sport-tile.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { BadgeComponent } from "./components/badge/badge.component";
import { TransactionDialogComponent } from './components/transactions/transaction-dialog/transaction-dialog.component';
import { PayoutMethodCardComponent } from './components/payout-method-card/payout-method-card.component';
import { PaypalPayoutCardComponent } from './components/payout-method-card/paypal-payout-card.component';
import { VenmoPayoutCardComponent } from './components/payout-method-card/venmo-payout-card.component';
import { StripePayoutCardComponent } from './components/payout-method-card/stripe-payout-card.component';
import { HandleControlComponent } from './components/handle-control/handle-control.component';

const COMPONENTS = [
  SideNavComponent,
  TopNavComponent,
  SchoolSportPreviewComponent,
  BlankComponent,
  UploadFileComponent,
  DocumentsComponent,
  DocumentViewComponent,
  DocumentListEntryComponent,
  PhoneMaskDirective,
  NotifierComponent,
  PageNotFoundComponent,
  PlayerDocsComponent,
  PlayerDocsViewComponent,
  PlayerDocsListComponent,
  LoadingSpinnerComponent,
  LoadingDirective,
  DocumentSignersModalComponent,
  ConfirmDialogComponent,
  SchoolsComponent,
  SchoolDeleteBoxComponent,
  SchoolImageUploadComponent,
  BlockLoaderDirective,
  LoadingBlockComponent,
  ButtonLoaderDirective,
  LoadingButtonComponent,
  PayoutTableComponent,
  SchoolSportUpdateCreateComponent,
  SchoolTemplateDesignViewComponent,
  SchoolTemplateDesignViewUpdateComponent,
  DownloadFileComponent,
  ConfigBannerComponent,
  VerificationResponseDialogComponent,
  StatsBannerComponent,
  SchoolLogoItemComponent,
  AllLogosModalComponent,
  BlankSelectionModalComponent,
  ProductModelMenuComponent,
  ProductsViewComponent,
  DesignSelectionModalComponent,
  AddressModalComponent,
  PrintLocationModalComponent,
  UnsavedChangesModalComponent,
  CopyToClipboardModalComponent,
  FileUploadComponent,
  LogoSelectorComponent,
  OnlyNumbersDirective,
  RemoveAriaOwnsDirective,
  DocumentViewerComponent,
  DesignLicensingComponent,
  ImagePreviewModalComponent,
  OrderAuditLogsModalComponent,
  SchoolSportTileComponent,
  TransactionsComponent,
  PayoutMethodCardComponent,
  PaypalPayoutCardComponent,
  VenmoPayoutCardComponent,
  StripePayoutCardComponent,
  TransactionDialogComponent
]


@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollbarModule,
    RelativeDateTimeModule,
    MatSnackBarModule,
    ClipboardModule,
    PageLayoutModule,
    BadgeComponent,
    HandleControlComponent
  ],
  exports: [
    ...COMPONENTS,
    MaterialModule,
    RouterModule,
    FormsModule,
    PhoneMaskDirective,
    OnlyNumbersDirective,
    HandleControlComponent
  ]
})
export class CoreModule { }
