import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LastPayout, Payout, PayoutItemStatus, Payouts, PayoutsFilter, PayoutsPaginatedData, Total, TotalDue, TriggerPayout } from '../../models/payouts';
import { HttpHelperService } from '../http-helper.service';
import { PaginatedRequest, Pagination } from '../../models/utils';
import { BadgeType } from '../../components/badge/badge.component';
import { SportSalesReport } from '../../models/sales';

export const transactionStatuses = ['next payout', 'Canceled', 'Success', 'Pending', 'Denied', 'FAILED'] as const;
export type TransactionStatus = typeof transactionStatuses[number];

export const payoutModels = ['school', 'schoolsport', 'player', 'organization', 'licenseentity'] as const;
export type PayoutModel = typeof payoutModels[number];


export type Transaction = {
  image: string;
  blankDescription: string;
  saleDate: Date;
  salePrice: number;
  sliceInfo: string;
  licenseId: number;
  slideId: number;
  licenseAmount: number;
  payout: number;
  transactionDate: number;
  transactionStatus: TransactionStatus;
  model?: PayoutModel;
  partnerId?: number;
  licenseDetails?: string;
}

export type PendingPayout = {
  modelName: PayoutModel;
  partnerId: number;
  partnerName?: string;
  total: number;
  salesValue: number;
  count: number;
  revSharePercentage: number;
  payoutMethod?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserPayoutsService {
  totalDue$: TotalDue = null;
  total$: Total = null;
  lastPayoutsDetails$:LastPayout[]=null;
  private endpoint: string = 'payouts';

  constructor(private httpHelper: HttpHelperService) { }

  resetState() {
    this.total$ = this.totalDue$ = null;
    this.lastPayoutsDetails$=null;
  }

  Total(filter?: Partial<{model: PayoutModel, partnerId: number}>): Observable<Total> {
    return this.httpHelper.request<Total>(`${this.endpoint}/total`, { method: 'GET', search: filter });
  }

  TotalDue(filter?: Partial<{model: PayoutModel, partnerId: number}>): Observable<TotalDue> {
    return this.httpHelper.request<TotalDue>(`${this.endpoint}/total_due`, { method: 'GET', search: filter });
  }

  getPayouts(filter? : PayoutsFilter): Observable<PayoutsPaginatedData> {
    return this.httpHelper.request<PayoutsPaginatedData>(`${this.endpoint}`, { method: 'GET', search: filter });
  }

  paginated(page: PaginatedRequest<Payout>): Observable<Pagination<Payout>> {
    return this.httpHelper.request<Pagination<Payout>>(this.endpoint, { method: 'GET', search: page });
  }

  getAllPayouts(filter? : PayoutsFilter): Observable<Payouts[]> {
    return this.httpHelper.request<Payouts[]>(`${this.endpoint}`, { method: 'GET', search: filter });
  }

  get(id: number): Observable<Payouts> {
    return this.httpHelper.request<Payouts>(`${this.endpoint}/${id}`);
  }

  triggerPayout(data: TriggerPayout): Observable<Payouts> {
    return this.httpHelper.request<Payouts>(`${this.endpoint}/user-trigger`, { method: 'POST', body: data });
  }

  lastPayout():Observable<LastPayout[]> {
    return this.httpHelper.request<LastPayout[]>(`payout-methods`, { method: 'GET' });
  }

  deletePayout(id:number){
    return this.httpHelper.request<void>(`payout-methods/${id}`, { method: 'DELETE' });
  }

  updatePayoutStatus(payout: Payout, status: string): Observable<Payout> {
    return this.httpHelper.request<Payout>(`${this.endpoint}/${payout.id}/update_status`, { method: 'POST', body: {status} });
  }

  transactions(payoutId: number, page: PaginatedRequest<Transaction>): Observable<Pagination<Transaction>> {
    const endpoint = payoutId ? `${this.endpoint}/${payoutId}/transactions` : `${this.endpoint}/transactions`;
    const search = page ?? {};
    return this.httpHelper.request<Pagination<Transaction>>(endpoint, { method: 'GET', search });
  }

  pending(page: PaginatedRequest<PendingPayout>, filters: Partial<{models: string[], upToDate: Date }>): Observable<Pagination<PendingPayout>> {
    const body = {
      models: filters.models,
      upToDate: filters.upToDate?.toISOString().split('T')[0]
    }
    return this.httpHelper.request<Pagination<PendingPayout>>(`${this.endpoint}/pending`, { method: 'POST', search: page, body });
  }

  pendingTotal(): Observable<{total: number}> {
    return this.httpHelper.request<{total: number}>(`${this.endpoint}/pending-total`, { method: 'GET' });
  }

  teamSales(payoutId: number): Observable<SportSalesReport[]> {
    return this.httpHelper.request<SportSalesReport[]>(`${this.endpoint}/${payoutId}/team-sales`, { method: 'GET' });
  }

  issuePayout(payoutId: number): Observable<Payout> {
    return this.httpHelper.request<Payout>(`${this.endpoint}/${payoutId}/issue`, { method: 'POST' });
  }

  payoutModelBadgeColor(model: PayoutModel): BadgeType {
    switch (model) {

      case 'school':
        return 'blue';
      case 'schoolsport':
        return 'purple';
      case 'player':
        return 'green';
      case 'organization':
        return 'yellow';
      case 'licenseentity':
        return 'pink';
      default:
        return 'blue';
    }
  }

  payoutStatusBadgeColor(status: PayoutItemStatus): BadgeType {
    switch (status) {
      case 'Success':
        return 'green';
      case 'Pending':
        return 'blue';
      case 'Unclaimed':
        return 'yellow';
      case 'Failed':
        return 'red';
      case 'Canceled':
        return 'red';
      case 'Denied':
        return 'red';
      case 'Blocked':
        return 'red';
      case 'OnHold':
        return 'yellow';
      case 'Refunded':
        return 'red';
      case 'Returned':
        return 'red';
      case 'Reversed':
        return 'red';
      default:
        return 'gray';
    }
  }

}
