import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlayerDesign, SchoolBlank, SchoolTemplates } from '../../models/school';
import { DesignBlank, SchoolDesignElements, SchoolTemplateDesign, storeType } from '../../models/templates';
import { HttpHelperService } from '../http-helper.service';
import { Blank } from '../../models/blank';
import { PaginatedData, PaginatedRequest } from '../../models/utils';

export type STDCreateWithElements = {
  name: string;
  elements: SchoolDesignElements[];
  licenseId?: number;
  schoolSportId?: number;
  playerId?: number;
  players?: number[];
  addNameToProducts?: boolean;
  storeTypes?: storeType[];
}

export type LicenseCategoryModel = {
  model: string;
  licenseType: string;
  licenseCode: string;
  description: string;
  blankId: number;
  productImage: string;
}

@Injectable({
  providedIn: 'root'
})
export class SchoolTemplateDesignsService {

  private endpoint: string = 'school-template-designs';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(filter?: any): Observable<SchoolTemplateDesign[]> {
    return this.httpHelper.request<SchoolTemplateDesign[]>(this.endpoint, { search: filter });
  }

  paginated(request: PaginatedRequest<SchoolTemplateDesign>): Observable<PaginatedData<SchoolTemplateDesign>> {
    return this.httpHelper.request<PaginatedData<SchoolTemplateDesign>>(this.endpoint, { method: 'GET', search: request });
  }

  get(id: number): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/${id}`);
  }

  create(item: SchoolTemplateDesign): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: SchoolTemplateDesign): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/${item.id}`, { body: item, method: 'PATCH' });
  }

  partialEdit(item: SchoolTemplateDesign): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/${item.id}`, { body: item, method: 'PATCH' });
  }

  delete(id: number): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/${id}`, { method: 'DELETE' });
  }

  createPlayerDesign(id: number, item: SchoolTemplateDesign): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(this.endpoint + '/' + id + '/create_design/', { body: item, method: 'POST' });
  }

  getPlayerDesigns(): Observable<PlayerDesign[]> {
    return this.httpHelper.request<PlayerDesign[]>('players/designs');
  }

  getSchoolDesignElements(id: number): Observable<SchoolDesignElements[]> {
    return this.httpHelper.request<SchoolDesignElements[]>(`${this.endpoint}/${id}/design_elements`);
  }

  getNewDesignElements(id: number, templateSideId): Observable<SchoolDesignElements[]> {
    return this.httpHelper.request<SchoolDesignElements[]>(`${this.endpoint}/${id}/new_design_elements`, {body: {templateSideId}, method: 'POST'});
  }

  saveDesignElements(id: number, elements: SchoolDesignElements[], image: string): Observable<{elements: SchoolDesignElements[], originalUpload: string | null}> {
    return this.httpHelper.request<{elements: SchoolDesignElements[], originalUpload: string | null}>(`${this.endpoint}/${id}/design_elements`, { body: {elements, image}, method: 'POST' });
  }

  approveDesign(id: number, data: SchoolDesignElements[]): Observable<SchoolTemplateDesign> {
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/${id}/approve/`, { body: data, method: 'POST' });
  }

  getImagePreview(data): Observable<any> {
    return this.httpHelper.request<any>(`render-design-proxy/`, { body: data, method: 'POST', responseType: 'blob' });
  }

  needsBuild(): Observable<SchoolTemplateDesign[]> {
    const params = {
      'build': true
    }
    return this.httpHelper.request<SchoolTemplateDesign[]>(`${this.endpoint}`, { search: params, method: 'GET' });
  }

  createForBulk(schoolId: number, name: string, multiSide: boolean): Observable<SchoolTemplateDesign> {
    const body = { schoolId, name, multiSide };
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/create_for_bulk/`, { body, method: 'POST' });
  }

  blanks(id: number): Observable<DesignBlank[]> {
    return this.httpHelper.request<DesignBlank[]>(`${this.endpoint}/${id}/blanks/`, { method: 'GET' });
  }

  allBlanks(id: number): Observable<{designBlanks: DesignBlank[], schoolBlanks: SchoolBlank[]}> {
    return this.httpHelper.request<{designBlanks: DesignBlank[], schoolBlanks: SchoolBlank[]}>(`${this.endpoint}/${id}/all_blanks/`, { method: 'GET' });
  }

  addBlank(designId: number, blankId: number): Observable<DesignBlank> {
    return this.httpHelper.request<DesignBlank>(`${this.endpoint}/${designId}/add_blank/`, { body: {blankId}, method: 'POST' });
  }

  deleteBlank(designId: number, blankId: number): Observable<boolean> {
    return this.httpHelper.request<boolean>(`${this.endpoint}/${designId}/delete_blank/`, { body: {blankId}, method: 'POST' });
  }

  possibleBlanks(id: number): Observable<Blank[]> {
    return this.httpHelper.request<Blank[]>(`${this.endpoint}/${id}/possible_blanks`, { method: 'GET' });
  }

  createWithAttributes(
    templateId: number, 
    design: SchoolTemplateDesign, 
    schoolId: number, 
    image?: ArrayBuffer | string, 
    imageBack?: ArrayBuffer | string
  ): Observable<SchoolTemplateDesign> {
    const body = {
      schoolId,
      templateId,
      design,
      image,
      imageBack
    }
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/create_with_attributes/`, { body, method: 'POST' });
  }

  createWithImage(
      templateId: number, 
      schoolId: number, 
      schoolSportId: number, 
      playerId: number, 
      image: File
  ): Observable<SchoolTemplateDesign> {
    const formData = new FormData();
    formData.append('schoolId', schoolId.toString());
    formData.append('templateId', templateId.toString());
    formData.append('schoolSportId', schoolSportId.toString());
    formData.append('playerId', playerId.toString());
    formData.append('image', image);
    return this.httpHelper.request<SchoolTemplateDesign>(`${this.endpoint}/create_with_image/`, { body: formData, method: 'POST' });
  }

  generateColorways(id: number): Observable<any[]> {
    return this.httpHelper.request<any[]>(`${this.endpoint}/${id}/generate-colorways/`, { method: 'GET' });
  };

  templates(school: number): Observable<{templateId: number, templateName: string}[]> {
    const search = { school };
    return this.httpHelper.request<{templateId: number, templateName: string}[]>(`${this.endpoint}/templates/`, { search, method: 'GET' });

  }

  licensingSheet(id: number, licenseType: string): Observable<LicenseCategoryModel[]> {
    return this.httpHelper.request<LicenseCategoryModel[]>(`${this.endpoint}/${id}/licensing-sheet/`, { search: {licenseType}, method: 'GET' });
  }


}
