import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { take, catchError, of, map, filter } from 'rxjs';
import { Code } from 'src/app/core/models/code';
import { PandaTemplate } from 'src/app/core/models/document';
import { School, SchoolSocialConfig } from 'src/app/core/models/school';
import { CodeService } from 'src/app/core/services/api/code.service';
import { CommonService } from 'src/app/core/services/api/common.service';
import { PandaTemplateService } from 'src/app/core/services/api/panda-template.service';
import { SchoolService } from 'src/app/core/services/api/school.service';
import { NotifierService } from 'src/app/core/services/notifier.services';
import { CodeStateService } from '../../../services/state/code-state.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { handleUniqueValidator } from 'src/app/core/validators/handle-unique.validator';
import { HandleService } from 'src/app/core/services/api/handle.service';
import { UsersStateService } from 'src/app/core/services/state/users-state.service';
import { ReportingEntityStateService } from 'src/app/core/services/state/reporting-entity-state.service';
import { HandleControlComponent } from '../../handle-control/handle-control.component';

@Component({
  selector: 'app-school-create-update',
  templateUrl: './school-create-update.component.html',
  styleUrls: ['./school-create-update.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    HandleControlComponent,
  ]
})
export class SchoolCreateUpdateComponent implements OnInit {
  codeStateService = inject(CodeStateService);

  defaultLicenseLoader: boolean = false;
  pandaTemplatesLoader: boolean = false;
  savingSchool: boolean = false;
  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  saveError = false;
  defaultLicenseTypes: Code[] = [];
  pandaTemplates: PandaTemplate[] = [];
  socialCreating = false;

  defaultLicenseTypes$ = this.codeStateService.getCodesByType("defaultLicenseType");
  statuses$ = this.codeStateService.getCodesByType("schoolStatus");

  entities$ = this.reportingEntityStateService.entities$;
  users$ = this.usersStateService.users$.pipe(
    filter(users => users?.length > 0),
    map(users => users.sort((a, b) => a.lastName.localeCompare(b.lastName)))
  );

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: School,
    private dialogRef: MatDialogRef<SchoolCreateUpdateComponent>,
    private fb: UntypedFormBuilder,
    private schoolService: SchoolService,
    public commonService: CommonService,
    private notifier: NotifierService,
    private codeService: CodeService,
    private pandaTemplateService: PandaTemplateService,
    public userService: UserService,
    private handleService: HandleService,
    private usersStateService: UsersStateService,
    private reportingEntityStateService: ReportingEntityStateService,
  ) {
    this.usersStateService.initCommissions();
    this.codeStateService.init();
    this.reportingEntityStateService.init();
    let pandaTemp = this.pandaTemplateService.pandaTemplates$
    if (pandaTemp == null) this.getPandaTemplates()
    else this.pandaTemplates = pandaTemp;
  }

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as School;
    }

    this.form = this.fb.group({
      id: this.defaults.id || null,
      fullName: [this.defaults.fullName || '', Validators.required],
      abbrName: [this.defaults.abbrName || '', Validators.required],
      nickname: [this.defaults.nickname || '', Validators.required],
      letters: [this.defaults.letters || '', Validators.required],
      mascot: [this.defaults.mascot || '', Validators.required],
      chant: [this.defaults.chant || '', Validators.required],
      status: [this.defaults.status || 'SETUP', Validators.required],
      noPayouts: [this.defaults.noPayouts || false],
      defaultLicenseType: [this.defaults.defaultLicenseType || '', Validators.required],
      playerRequiredTemplates: [this.defaults.playerRequiredTemplates || []],
      handleString: [this.defaults.handleString || ''],
      commissionsUser: [this.defaults.commissionsUser || null],
      reportingEntities: [this.defaults.reportingEntities || []],
    });
  }

  getPandaTemplates() {
    this.pandaTemplatesLoader = true;
    this.pandaTemplateService.getAll().pipe(take(1)).subscribe(tempaltes => {
      this.pandaTemplatesLoader = false;
      this.pandaTemplates = tempaltes;
      this.pandaTemplateService.pandaTemplates$ = tempaltes
    })
  }

  getContextData(): void {
    this.defaultLicenseLoader = true;
    this.pandaTemplatesLoader = true;
    this.codeService.filter({ codeType: "defaultLicenseType" }).pipe(take(1)).subscribe(codes => {
      this.defaultLicenseLoader = false;
      this.defaultLicenseTypes = codes;
    })
    this.pandaTemplateService.getAll().pipe(take(1)).subscribe(tempaltes => {
      this.pandaTemplatesLoader = false;
      this.pandaTemplates = tempaltes;
    })
  }

  save() {
    if (this.mode === 'create') {
      this.createSchool();
    } else if (this.mode === 'update') {
      this.updateSchool();
    }
  }

  createSchool() {
    const school = this.form.value;
    this.savingSchool = true;
    this.schoolService.create(school).subscribe(createddSchool => {
      this.savingSchool = false;
      if (createddSchool) {
        this.dialogRef.close(createddSchool);
      } else {
        this.saveError = true;
      }
    })
  }

  updateSchool() {
    const school = this.form.value;
    this.savingSchool = true;
    this.schoolService.edit(school).subscribe(updatedSchool => {
      this.savingSchool = false;
      if (updatedSchool) {
        this.dialogRef.close(updatedSchool);
      } else {
        this.saveError = true;
      }
    })
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
