import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { PayoutMethod } from '../../models/payouts';


@Injectable({
  providedIn: 'root'
})
export class PayoutMethodsService {

  private endpoint: string = 'payout-methods';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<PayoutMethod[]> {
    return this.httpHelper.request<PayoutMethod[]>(this.endpoint);
  }

  get(id: number): Observable<PayoutMethod> {
    return this.httpHelper.request<PayoutMethod>(`${this.endpoint}/${id}`);
  }

  create(item: PayoutMethod): Observable<PayoutMethod> {
    return this.httpHelper.request<PayoutMethod>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  createStripeAccount(): Observable<{payoutMethod: PayoutMethod, accountLink: string}> {
    return this.httpHelper.request<{payoutMethod: PayoutMethod, accountLink: string}>(`${this.endpoint}/create-stripe-account`, { method: 'POST' });
  }

  stripeAccountLink(payoutMethodId: number): Observable<{clientSecret: string}> {
    return this.httpHelper.request<{clientSecret: string}>(`${this.endpoint}/${payoutMethodId}/stripe-dashboard-link`, { method: 'GET' });
  }

  stripeAccountInfo(payoutMethodId: number): Observable<PayoutMethod> {
    return this.httpHelper.request<PayoutMethod>(`${this.endpoint}/${payoutMethodId}/check_account_status`, { method: 'GET' });
  }
}
