import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { 
  AbstractControl, 
  AsyncValidatorFn,
  ControlValueAccessor, 
  FormControl, 
  NG_VALIDATORS, 
  NG_VALUE_ACCESSOR, 
  ReactiveFormsModule, 
  ValidationErrors, 
  Validator, 
  Validators 
} from '@angular/forms';
import { HandleService } from '../../services/api/handle.service';
import { handleUniqueValidator } from '../../validators/handle-unique.validator';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, first, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-handle-control',
  templateUrl: './handle-control.component.html',
  styleUrls: ['./handle-control.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HandleControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HandleControlComponent),
      multi: true
    }
  ]
})
export class HandleControlComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() initialValue: string = '';
  @Input() prefix: string = '';
  @Input() hint: string = 'Handle/Sub-Domain of the store (i.e handle.athsolutions.shop)';
  @Input() label: string = 'Handle';
  @Input() required: boolean = true;
  @Input() minLength: number = 4;
  @Input() maxLength: number = 25;

  handleControl = new FormControl('', {
    validators: [],
    asyncValidators: [],
    updateOn: 'blur'
  });

  // Add loading state indicator
  isCheckingUniqueness = false;

  // ControlValueAccessor methods
  onChange: any = () => {};
  onTouched: any = () => {};
  disabled: boolean = false;

  constructor(private handleService: HandleService) {}

  ngOnInit(): void {
    // Set up validators
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.minLength) {
      validators.push(Validators.minLength(this.minLength));
    }
    if (this.maxLength) {
      validators.push(Validators.maxLength(this.maxLength));
    }

    this.handleControl.setValidators(validators);
    this.handleControl.setAsyncValidators(
      this.uniquenessValidator()
    );

    // Subscribe to value changes
    this.handleControl.valueChanges.subscribe(value => {
      if (value !== null) {
        // Convert to lowercase
        const lowerCaseValue = value.toLowerCase();
        if (lowerCaseValue !== value) {
          this.handleControl.setValue(lowerCaseValue, { emitEvent: false });
        }
        this.onChange(lowerCaseValue);
      } else {
        this.onChange(value);
      }
    });

    // Subscribe to status changes to track when async validation starts/ends
    this.handleControl.statusChanges.subscribe(status => {
      this.isCheckingUniqueness = status === 'PENDING';
    });
  }

  // Custom validator that also tracks loading state
  uniquenessValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.initialValue === this.prefix + control.value) {
        return of(null);
      }
      
      // Set loading state to true when validation starts
      this.isCheckingUniqueness = true;
      
      return control.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(value => this.prefix + value),
        switchMap(value => {
          return this.handleService.available(value);
        }),
        map(available => (available ? null : {'handleExists': true})),
        first(),
        finalize(() => {
          // Set loading state to false when validation completes
          this.isCheckingUniqueness = false;
        })
      );
    };
  }

  // ControlValueAccessor implementation
  writeValue(value: string): void {
    if (value !== null && value !== undefined) {
      this.handleControl.setValue(value.toLowerCase(), { emitEvent: false });
    } else {
      this.handleControl.setValue('', { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.handleControl.disable();
    } else {
      this.handleControl.enable();
    }
  }

  // Validator implementation
  validate(control: AbstractControl): ValidationErrors | null {
    return this.handleControl.errors;
  }

  // Helper method to handle input changes
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.toLowerCase();
    this.handleControl.setValue(value);
    this.onTouched();
  }
} 