import { PrintLocation } from "./design";
import { Player, SchoolSport, SchoolTemplates } from "./school";

export const licensingStatuses = ['NO_ACTION', 'SUBMITTED', 'APPROVED', 'REJECTED'] as const;
export type licensingStatus = typeof licensingStatuses[number];

export const storeTypes = ['brand', 'team', 'player'] as const;
export type storeType = typeof storeTypes[number];

export interface Templates {
  id?: number;
  name: string;
  description: string;
  type?: 'emb' | 'dtf' | 'dtg';
  sqlQuery: any;
  blanks?: any;
  image?: string;
  multiDesign?: boolean;
  multiSide?: boolean;
  userSubmitted?: boolean;
  playerAllowed?: boolean;
  sides?: TemplateSide[];
  quickAdd?: boolean;
  variableData?: boolean;
  elements?: SchoolDesignElements[];
  storeTypes?: storeType[];
}

export interface TemplatesPagination {
  count: number,
  next: string,
  previous: string,
  results: Templates[]
}

export interface TemplateBlanks {
  id?: number;
  templateId?: number;
  blankModel?: string;
  colorCnt?: number;
  salePrice?: number;
}

export interface TemplateAttributes {
  id?: number;
  attributeType?: string;
  fieldName?: string;
  niceName?: string;
  defaultValue?: string;
  template?: number
}

export interface SchoolTemplateAttributes {
  id?: number;
  attributeType?: string;
  fieldName?: string;
  fieldValue?: string;
  schoolTemplate?: SchoolTemplates;
  demoContent?: string
}

export interface SchoolTemplateDesign {
  id?: number;
  name?: string;
  schoolTemplate?: number;
  schoolSport?: number | SchoolSport;
  player?: number | Player;
  licenseId?: number;
  license?: number;
  approved?: boolean;
  approvedAt?: Date;
  rejectedReason?: string;
  rejectedAt?: Date;
  lightColorway?: string;
  darkColorway?: string;
  whiteColorway?: string;
  primaryColorway?: string;
  secondaryColorway?: string;
  lightBackColorway?: string;
  darkBackColorway?: string;
  whiteBackColorway?: string;
  primaryBackColorway?: string;
  secondaryBackColorway?: string;
  createdAt?: Date;
  originalUpload?: string;
  originalUploadBack?: string;
  buildAt?: Date;
  needsDesignCleanup?: boolean;
  schoolId?: number;
  template?: Templates;
  printLocations?: PrintLocation[];
  elements?: SchoolDesignElements[];
  storeTypes?: storeType[];
  playerName?: string;
  teamName?: string;
  templateId?: number;
  userSubmitted?: boolean;
  multiSide?: boolean;
  licensingStatus?: licensingStatus;
  lastLicenseAction?: Date;
  school?: number;
}

export type DesignBlank = {
  id?: number;
  blank: number;
  design: number;
}

export interface SchoolTemplateDesignDetail {
  id?: number;
  name?: string;
  schoolTemplate?: SchoolTemplates;
  schoolSport?: number | SchoolSport;
  player?: number | Player;
  approved?: boolean;
  approvedAt?: Date;
  rejectedReason?: string;
  rejectedAt?: Date;
  lightColorway?: string;
  darkColorway?: string;
  whiteColorway?: string;
  primaryColorway?: string;
  secondaryColorway?: string;
  lightBackColorway?: string;
  darkBackColorway?: string;
  whiteBackColorway?: string;
  primaryBackColorway?: string;
  secondaryBackColorway?: string;
  createdAt?: Date;
  originalUpload?: string;
  originalUploadBack?: string;
  buildAt?: Date;
  needsDesignCleanup?: boolean;
  schoolId?: number;
  printLocations?: PrintLocation[];
}

export interface SchoolTemplateDesignFull {
  id?: number;
  name?: string;
  schoolTemplate?: number;
  schoolSport?: SchoolSport;
  player?: Player;
  approved?: boolean;
  approvedAt?: Date;
  rejectedReason?: string;
  rejectedAt?: Date;
  lightColorway?: string;
  darkColorway?: string;
  whiteColorway?: string;
  primaryColorway?: string;
  secondaryColorway?: string;
  originalUpload?: string;
  createdAt?: Date;
}

export interface TemplateSide {
  id: number;
  template: number;
  side: string;
  orderType: string;
  versionCode: string;
  printLocation: string;
  mergeLocation: string;
  required: boolean;
  enabled?: boolean;
}

export interface SchoolDesignElements {
  id: number,
  templateSide: TemplateSide;
  attributes: DesignElementAttribute[],
  paramId: number,
  name: string,
  niceName: string,
  baseObject: string,
  display: boolean,
  isText: boolean,
  template: number | Templates,
  schoolTemplateDesign: number | SchoolTemplateDesign
}
export interface DesignElementAttribute {
  id: 0;
  name: 'font' | 'text' | 'logo' | 'fillcolor' | 'strokecolor' | 'logodesign' | 'textdesign' | 'textcolor' | 'designcolor' | 'archlogo';
  value: string;
}

export interface RenderDesign {
  schoolId: number,
  templateId: number,
  sideVersionCode?: string;
  designElements: SchoolDesignElements[] | [];
}
